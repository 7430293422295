export const APP_CHANGE_LOADING_STATE = "APP/CHANGE_LOADING_STATE"
export const APP_CLEAR_STATE = "APP/CLEAR_STATE"

export const APP_GET_PROFILE_REQUEST = "APP/GET_PROFILE/REQUEST"
export const APP_GET_PROFILE_SUCCESS = "APP/GET_PROFILE/SUCCESS"
export const APP_GET_PROFILE_ERROR = "APP/GET_PROFILE/ERROR"

export const APP_UPDATE_PROFILE_REQUEST = "APP/UPDATE_PROFILE/REQUEST"
export const APP_UPDATE_PROFILE_SUCCESS = "APP/UPDATE_PROFILE/SUCCESS"
export const APP_UPDATE_PROFILE_ERROR = "APP/UPDATE_PROFILE/ERROR"

export const APP_GET_USERS_REQUEST = "APP/GET_USERS/REQUEST"
export const APP_GET_USERS_SUCCESS = "APP/GET_USERS/SUCCESS"
export const APP_GET_USERS_ERROR = "APP/GET_USERS/ERROR"

export const APP_GET_SIGNDOC_REQUEST = "APP/GET_SIGNDOC/REQUEST"
export const APP_GET_SIGNDOC_SUCCESS = "APP/GET_SIGNDOC/SUCCESS"
export const APP_GET_SIGNDOC_ERROR = "APP/GET_SIGNDOC/ERROR"
